import loupe from "@/img/icons/loupe.svg";
import grid from "@/img/icons/grid.svg";
import gridFill from "@/img/icons/grid-fill.svg";
import appsFill from "@/img/icons/apps-fill.svg";
import filterFill from "@/img/icons/filter-fill.svg";
import shuffle from "@/img/icons/shuffle.svg";
import ProjectBoard from "@/img/icons/project-board.svg";
import loader from "@/img/icons/loader.svg";
import pending from "@/img/icons/pending.svg";
import score from "@/img/icons/score.svg";
import PolygonShape from "@/img/icons/shape/polygon.svg";
import RectangleShape from "@/img/icons/shape/rectangle.svg";
import PointShape from "@/img/icons/shape/point.svg";
import LineShape from "@/img/icons/shape/line.svg";
import ClassificationShape from "@/img/icons/shape/classification.svg";
import PencilCircleFill from "@/img/icons/pencil-circle-fill.svg";
import DoubleCheck from "@/img/icons/double-check.svg";
import scissors from "@/img/icons/scissors.svg";
import ZoomReset from "@/img/icons/zoom-reset.svg";
import cursor from "@/img/icons/cursor.svg";
import hand from "@/img/icons/grab.svg";
import save from "@/img/icons/save.svg";
import keyboard from "@/img/icons/keyboard.svg";
import labelHide from "@/img/icons/label-hide.svg";
import labelShow from "@/img/icons/label-show.svg";
import properties from "@/img/icons/properties.svg";
import LockFill from "@/img/icons/lock-fill.svg";
import UnlockFill from "@/img/icons/unlock-fill.svg";
import upload from "@/img/icons/upload.svg";
import play from "@/img/icons/play.svg";
import pause from "@/img/icons/pause.svg";
import next from "@/img/icons/next.svg";
import previous from "@/img/icons/previous.svg";
import annotation from "@/img/icons/annotation.svg";
import pencilPlus from "@/img/icons/pencil-plus.svg";
import pencilMinus from "@/img/icons/pencil-minus.svg";
import BrandPicsellia from "@/img/brands/picsellia.svg";
import BrandDocker from "@/img/brands/docker.svg";
import BrandGoogleCollab from "@/img/brands/google-collab.svg";
import BrandCoco from "@/img/brands/coco.svg";
import BrandYolo from "@/img/brands/yolo.svg";
import BrandPvoc from "@/img/brands/pvoc.svg";
import BrandS3 from "@/img/brands/S3.svg";
import BrandMinio from "@/img/brands/minio.svg";
import BrandGCP from "@/img/brands/gcp.svg";
import BrandAzure from "@/img/brands/azure.svg";
import BrandOVH from "@/img/brands/ovh.svg";

export default {
  loupe,
  grid,
  "grid-fill": gridFill,
  "apps-fill": appsFill,
  "filter-fill": filterFill,
  shuffle,
  "project-board": ProjectBoard,
  loader,
  pending,
  score,
  upload,
  annotation,
  scissors,
  "zoom-reset": ZoomReset,
  cursor,
  hand,
  save,
  keyboard,
  "label-hide": labelHide,
  "label-show": labelShow,
  properties,
  "lock-fill": LockFill,
  "unlock-fill": UnlockFill,
  "pencil-circle-fill": PencilCircleFill,
  "double-check": DoubleCheck,
  "play-video": play,
  "pause-video": pause,
  previous,
  next,
  "pencil-plus": pencilPlus,
  "pencil-minus": pencilMinus,
  "brand-picsellia": BrandPicsellia,
  "brand-docker": BrandDocker,
  "brand-google-collab": BrandGoogleCollab,
  "brand-coco": BrandCoco,
  "brand-yolo": BrandYolo,
  "brand-pvoc": BrandPvoc,
  "brand-OVH": BrandOVH,
  "brand-AWS": BrandS3,
  "brand-MINIO": BrandMinio,
  "brand-GOOGLE": BrandGCP,
  "brand-AZURE": BrandAzure,
  polygon: PolygonShape,
  rectangle: RectangleShape,
  point: PointShape,
  line: LineShape,
  classification: ClassificationShape,
};
