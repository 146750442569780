import PxlApiClient from "openapi-client/src/ApiClient.js";

// Replace null values by undefined
// Inkline does not support null values
PxlApiClient.convertToType = (data, type) => {
  if (data === null || data === undefined) return undefined;

  switch (type) {
    case "Boolean":
      return Boolean(data);
    case "Integer":
      return parseInt(data, 10);
    case "Number":
      return parseFloat(data);
    case "String":
      return String(data);
    case "Date":
      return Client.parseDate(String(data));
    case "Blob":
      return data;
    default:
      if (type === Object) {
        // generic object, return directly
        return data;
      } else if (typeof type.constructFromObject === "function") {
        // for model type like User and enum class
        return type.constructFromObject(data);
      } else if (Array.isArray(type)) {
        // for array type like: ['String']
        const itemType = type[0];

        return data.map((item) => {
          return Client.convertToType(item, itemType);
        });
      } else if (typeof type === "object") {
        // for plain object type like: {'String': 'Integer'}
        let keyType, valueType;
        for (const k in type) {
          if (Object.prototype.hasOwnProperty.call(type, k)) {
            keyType = k;
            valueType = type[k];
            break;
          }
        }

        const result = {};
        for (const k in data) {
          if (Object.prototype.hasOwnProperty.call(data, k)) {
            const key = Client.convertToType(k, keyType);
            result[key] = Client.convertToType(data[k], valueType);
          }
        }

        return result;
      } else {
        // for unknown type, return the data directly
        return data;
      }
  }
};

class Client extends PxlApiClient {
  constructor(basePath = "") {
    super(basePath);
    this.defaultHeaders = {
      "X-CSRFTOKEN": this.getCookie("csrftoken"),
    };
  }

  getCookie(name) {
    name = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
export default new Client();
